import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { CardMedia } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  image: {
    minHeight: '10px',
    margin: 0
  }
}));

const Image = (props) => {
  const classes = useStyles();

  return (
    <CardMedia {...props} className={`${classes.image} ${props.className}`} />
  );
};

export default Image;
